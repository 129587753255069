@import "../../styles/variaveis";
section#Properties {
  margin: 8px 0;
  article#Properties {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    // gap: 5px;
    padding: 5px;
    @include border;
    section {
      padding: 2px;
      display: grid;
      gap: 2px;
      grid-template-columns: 1fr 2fr;
      &.create {
        grid-template-columns: 1fr 1fr 1fr;
      }
      div {
        padding: 5px;
        @include border;
      }
    }
  }
}
