main#Companies .companiesList {
  display: flex;
  gap: 7px;
  padding: 10px 0;
  flex-wrap: wrap;
}
main#Companies .companiesList .card {
  flex-basis: 220px;
  padding: 7px;
  border: 1px solid black;
  border-radius: 5px;
}/*# sourceMappingURL=companies.css.map */