header#Header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px;
}
header#Header nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media print {
  header#Header {
    display: none;
  }
}