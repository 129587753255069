* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: sans-serif;
}

button {
  padding: 5px;
}

h1 {
  font-size: 2em;
  font-weight: 500;
}

main {
  padding: 5px;
}

.error {
  color: rgb(252, 13, 13);
}

input {
  border: 1px solid #333;
  border-radius: 3px;
  border-radius: 3px;
  padding: 3px;
  margin: 5px;
}
input.error, input:invalid {
  border: 1px red solid;
}