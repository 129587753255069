main#workers section#workersList {
  padding: 10px 0;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  flex-direction: column;
}
main#workers section#workersList .card {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
}/*# sourceMappingURL=workers.css.map */