section.InputComponent {
  position: relative;
  margin: 5px 0;
}
section.InputComponent input {
  font-size: 16px;
  padding: 5px 5px 5px 3px;
  min-width: 200px;
  border: none;
  border-bottom: 1px solid #1d1d1d;
}
section.InputComponent input:focus, section.InputComponent input.active {
  outline: none;
}
section.InputComponent input:focus ~ label, section.InputComponent input.active ~ label {
  -webkit-transform: translate(-5px, -17px);
          transform: translate(-5px, -17px);
  font-size: 13px;
}
section.InputComponent label {
  color: #555555;
  font-size: 15px;
  font-weight: normal;
  position: absolute;
  left: 6px;
  top: 12px;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}