.Modal {
  background-color: rgba(0, 0, 0, 0.4431372549);
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.Modal main {
  transition: 0.4s ease-out;
  overflow: auto;
  position: relative;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  min-width: 75vw;
  max-height: 99vh;
  min-height: 60vh;
}
.Modal main span.material-symbols-outlined {
  position: absolute;
  right: 3px;
  top: 3px;
  float: left;
  cursor: pointer;
}

@media (max-width: 600px) {
  .Modal main {
    min-width: 95vw;
  }
}/*# sourceMappingURL=styles.css.map */