section#ConsumesPage {
  display: flex;
  flex-direction: column;
  gap: 2px;
  border: 1px solid #333;
  border-radius: 3px;
  padding: 2px;
  overflow: auto;
  position: relative;
}
section#ConsumesPage .header {
  border: 1px solid #333;
  border-radius: 3px;
  padding: 3px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  position: sticky;
  top: 0px;
  background: white;
}
section#ConsumesPage .header span {
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}
section#ConsumesPage .header span:first-child {
  justify-content: start;
}
section#ConsumesPage .container {
  overflow: auto;
  max-height: 35vh;
}
section#ConsumesPage .container .dateSelector {
  display: flex;
  padding: 2px;
  justify-content: space-around;
}
section#ConsumesPage .container .dateSelector span {
  cursor: pointer;
}
section#ConsumesPage .container .consume {
  margin: 5px 0;
  border: 1px solid #333;
  border-radius: 3px;
  padding: 3px 0;
}
section#ConsumesPage .container .consume .infos {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}
section#ConsumesPage .container .consume .infos span {
  display: flex;
  justify-content: center;
  padding: 2px;
}
section#ConsumesPage .container .consume .infos span:first-child {
  justify-content: start;
}
section#ConsumesPage section#Resume {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  padding: 5px;
  position: sticky;
  bottom: 0px;
  background: white;
}
section#ConsumesPage section#Resume span {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  section#Consumes .header {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
  section#Consumes .header span:first-child {
    justify-content: center;
  }
  section#Consumes .container .consume .infos {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
  section#Consumes .container .consume .infos span:first-child {
    justify-content: center;
  }
}/*# sourceMappingURL=styles.css.map */