main#consumesPage section#monthSelector {
  display: flex;
  justify-content: space-around;
  margin: 10px;
  padding: 1px;
  border-radius: 3px;
  border: 1px solid #000;
}
main#consumesPage section#monthSelector div {
  cursor: pointer;
}
main#consumesPage section#ConsumesTable {
  display: flex;
  flex-direction: column;
  background-color: black;
  gap: 1px;
  margin: 10px;
  padding: 1px;
  border-radius: 3px;
}
main#consumesPage section#ConsumesTable header {
  background-color: #fff;
}
main#consumesPage section#ConsumesTable div.workerConsume consumebutton {
  display: flex;
  justify-content: center;
  align-items: center;
}
main#consumesPage section#ConsumesTable div.workerConsume div.botton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 5px;
}
main#consumesPage section#ConsumesTable div.workerConsume,
main#consumesPage section#ConsumesTable header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.5fr;
  padding: 3px;
  background-color: #fff;
  border-radius: 3px;
}
main#consumesPage section#ConsumesTable div.workerConsume div,
main#consumesPage section#ConsumesTable header div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}
main#consumesPage section#ConsumesTable div.workerConsume div:first-child,
main#consumesPage section#ConsumesTable header div:first-child {
  justify-content: start;
}