main#AdmissionSheat div.page {
  margin: 0 auto;
  width: 210mm;
  height: 297mm;
}
main#AdmissionSheat div.page form#createDependents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
main#AdmissionSheat div.page fieldset {
  border: 1px solid #1d1d1d;
  border-radius: 5px;
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-columns: 1fr 5px 1fr 5px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
}
main#AdmissionSheat div.page fieldset#horas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
main#AdmissionSheat div.page fieldset div#experiencia,
main#AdmissionSheat div.page fieldset div#transport {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
}
main#AdmissionSheat div.page fieldset div#ctpd {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
main#AdmissionSheat div.page fieldset div#ctpd div.datas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
main#AdmissionSheat div.page fieldset div#dependentes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px;
}
main#AdmissionSheat div.page fieldset div#dependentes div.dependent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5px;
  gap: 5px;
  border: 1px solid #1d1d1d;
  border-radius: 5px;
}

@media print {
  main#AdmissionSheat button {
    display: none;
  }
  main#AdmissionSheat div.page form#createDependents {
    display: none;
  }
}