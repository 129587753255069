section#Properties {
  margin: 8px 0;
}
section#Properties article#Properties {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 1px solid #333;
  border-radius: 3px;
}
section#Properties article#Properties section {
  padding: 2px;
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 2fr;
}
section#Properties article#Properties section.create {
  grid-template-columns: 1fr 1fr 1fr;
}
section#Properties article#Properties section div {
  padding: 5px;
  border: 1px solid #333;
  border-radius: 3px;
}/*# sourceMappingURL=styles.css.map */